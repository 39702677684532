.menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 35px;
    padding: 5px 15px 5px 0px;
    border: none;
    background: transparent;
    font-size: 14px;
    gap: 10px;

    &.menu-btn-quiet {
        background-color: transparent;
        color: var(--ceres-primary-color-1);
        font-weight: bold;

        &:hover {
            filter: brightness(0.9);
        }
    }
}
