:root {
    // Siemens corporate colors
    //======================================================================
    --ceres-primary-color-1: #009999; // primary
    --ceres-primary-color-1-opaque: rgba(0, 153, 153, 0.1);
    --ceres-primary-color-2: #000028; // text
    --ceres-primary-color-3: #333353;
    --ceres-primary-color-4: #66667e;
    --ceres-primary-color-5: #9999a9;
    --ceres-primary-color-6: #ccccd4; //button-disabled
    --ceres-primary-color-7: #e5e5e9; //other-disabled
    --ceres-primary-color-8: #e9e9e9dd; //input-disabled

    --ceres-secondary-color-1: #00646e; //combo-chart report-teamlead-chart
    --ceres-secondary-color-1-opacity-8: rgba(0, 100, 110, 0.8);
    --ceres-secondary-color-2: #00af8e;
    --ceres-secondary-color-3: #00d7a0;
    --ceres-secondary-color-4: #00ffb9;
    --ceres-secondary-color-5: #00557c; //typo links
    --ceres-secondary-color-5-opacity-8: rgba(0, 85, 124, 0.8);
    --ceres-secondary-color-6: #0087be; //typo links border-color background-a-icons gms-reporting-header-colors dropzones dragzone upload-item
    --ceres-secondary-color-6-opacity-8: rgba(0, 135, 190, 0.8);
    --ceres-secondary-color-7: #00bedc;
    --ceres-secondary-color-8: #00e6dc;
    --ceres-secondary-color-9: #500078;
    --ceres-secondary-color-10: #aa32be;
    --ceres-secondary-color-11: #ffd732;
    --ceres-secondary-color-12: #ec6602;
    --ceres-secondary-color-12-opacity-8: rgba(236, 102, 2, 0.8);
    --ceres-secondary-color-13: #ff9000;
    --ceres-secondary-color-13-opacity-5: rgba(255, 144, 0, 0.5);
    --ceres-secondary-color-14: #ef0137;
    --ceres-secondary-color-14-opaque-1: rgba(239, 1, 55, 0.1);
    --ceres-secondary-color-15: #fe8389;
    --ceres-secondary-color-16: #aaaa96;
    --ceres-secondary-color-17: #c5c5b8;
    --ceres-secondary-color-18: #dfdfd9;
    --ceres-secondary-color-19: #e5e5e9;

    --ceres-feedback-blue: #007eb1;
    --ceres-feedback-green: #01893a;
    --ceres-feedback-green-text: #018136;
    --ceres-feedback-yellow: #e9c32a;
    --ceres-feedback-orange: #e96401;
    --ceres-feedback-red: #d72339;
    --ceres-feedback-red-text: #b81e31;

    --ceres-input-background: #f6f6f6;
    --ceres-input: var(--ceres-primary-color-2);
    // Read only css variables
    //======================================================================
    --ceres-footer-color: var(--ceres-primary-color-7);
    --ceres-sidenav-color: var(--ceres-primary-color-3);
    --ceres-wizard-background: var(--ceres-primary-color-6);

    --ceres-gradient-1-1: var(--ceres-secondary-color-4);
    --ceres-gradient-1-2: var(--ceres-secondary-color-8);
    --ceres-gradient-2-1: var(--ceres-secondary-color-3);
    --ceres-gradient-2-2: var(--ceres-secondary-color-7);
    --ceres-gradient-3-1: var(--ceres-primary-color-2);
    --ceres-gradient-3-2: var(--ceres-primary-color-1);
    --ceres-gradient-4-1: var(--ceres-primary-color-2);
    --ceres-gradient-4-2: var(--ceres-secondary-color-2);
    --ceres-gradient-5-1: var(--ceres-primary-color-2);
    --ceres-gradient-5-2: var(--ceres-secondary-color-6);
    --ceres-gradient-news: linear-gradient(93deg, transparent 50%, rgba(0, 0, 0, 0.5) 80%, transparent 90%);
    --ceres-opacity-pre-staffing: 0.5;

    //Util colors [So far used in ceres (partially adapted to Siemens corporate colors)]
    //======================================================================
    --ceres-black: #000000;
    --ceres-white: #ffffff;

    --ceres-static-black: #000;
    --ceres-static-white: #fff;
    --ceres-white-opaque: rgba(256, 256, 256, 0.5);
    --ceres-black-opaque-0: rgba(0, 0, 0, 0); // transparent
    --ceres-black-opaque-05: rgba(0, 0, 0, 0.05);
    --ceres-black-opaque-1: rgba(0, 0, 0, 0.1);
    --ceres-black-opaque-12: rgba(0, 0, 0, 0.12);
    --ceres-black-opaque-14: rgba(0, 0, 0, 0.14);
    --ceres-black-opaque-2: rgba(0, 0, 0, 0.2);
    --ceres-black-opaque-25: rgba(0, 0, 0, 0.25);
    --ceres-black-opaque-35: rgba(0, 0, 0, 0.35);
    --ceres-black-opaque-5: rgba(0, 0, 0, 0.5);
    --ceres-black-opaque-54: rgba(0, 0, 0, 0.54);
    --ceres-black-opaque-57: rgba(0, 0, 0, 0.57);
    --ceres-black-opaque-6: rgba(0, 0, 0, 0.6);

    --ceres-classification-green-1: #ccffcc; // [rgb(204, 255, 204)]
    --ceres-classification-green-2: #00ff00; // [rgb(0, 255, 0)]
    --ceres-classification-green-3: #339966; // [rgb(51, 153, 102)]
    --ceres-classification-green-4: #008000; // [rgb(0, 128, 0)]
    --ceres-classification-green-5: #003300; // [rgb(0, 51, 0)]
    --ceres-mat-select-background: #fff;
    --ceres-form-field-background: #f6f6f6;
    --ceres-slide-toggle-thumb: #fafafa;
    --ceres-slide-toggle-track: #00000061;
    --ceres-timesheets-header-background: #e5e5e9;
    --ceres-disabled-form-field-opacity: 0.5;
    --ceres-label-color: #4a4a4a;
}

[data-theme='dark'] {
    --ceres-primary-color-1: #00ffb9; //#009999; // primary
    --ceres-primary-color-1-opaque: rgba(0, 153, 153, 0.1);
    --ceres-primary-color-2: #fff; // text
    --ceres-primary-color-3: #dfdfd9;
    --ceres-primary-color-4: #66667e;
    --ceres-primary-color-5: #fff;
    --ceres-primary-color-6: #ccccd4; //button-disabled
    --ceres-primary-color-7: #e5e5e9; //other-disabled
    --ceres-primary-color-8: #00183b; //#e9e9e9dd; //input-disabled

    --ceres-secondary-color-1: #00646e; //combo-chart report-teamlead-chart
    --ceres-secondary-color-1-opacity-8: rgba(0, 100, 110, 0.8);
    --ceres-secondary-color-2: #00af8e;
    --ceres-secondary-color-3: #00d7a0;
    --ceres-secondary-color-4: #00ffb9;
    --ceres-secondary-color-5: #00557c; //typo links
    --ceres-secondary-color-5-opacity-8: rgba(0, 85, 124, 0.8);
    --ceres-secondary-color-6: #0087be; //typo links border-color background-a-icons gms-reporting-header-colors dropzones dragzone upload-item
    --ceres-secondary-color-6-opacity-8: rgba(0, 135, 190, 0.8);
    --ceres-secondary-color-7: #00bedc;
    --ceres-secondary-color-8: #00e6dc;
    --ceres-secondary-color-9: #500078;
    --ceres-secondary-color-10: #aa32be;
    --ceres-secondary-color-11: #ffd732;
    --ceres-secondary-color-12: #ec6602;
    --ceres-secondary-color-12-opacity-8: rgba(236, 102, 2, 0.8);
    --ceres-secondary-color-13: #ff9000;
    --ceres-secondary-color-13-opacity-5: rgba(255, 144, 0, 0.5);
    --ceres-secondary-color-14: #ef0137;
    --ceres-secondary-color-14-opaque-1: rgba(239, 1, 55, 0.1);
    --ceres-secondary-color-15: #fe8389;
    --ceres-secondary-color-16: #aaaa96;
    --ceres-secondary-color-17: #c5c5b8;
    --ceres-secondary-color-18: #dfdfd9;
    --ceres-secondary-color-19: #030d31;

    --ceres-input-background: #f6f6f626;
    --ceres-input: #fff;
    --ceres-footer-color: #00183b;
    --ceres-sidenav-color: #00183b;
    --ceres-wizard-background: var(--ceres-primary-color-6);
    --ceres-opacity-pre-staffing: 0.9;

    --ceres-white: #000228;
    --ceres-black: #ffffff;

    --ceres-feedback-blue: #00bedc;
    --ceres-feedback-green: #01d65a;
    --ceres-feedback-green-text: #01d65a;
    --ceres-feedback-yellow: #ffd732;
    --ceres-feedback-orange: #ff9000;
    --ceres-feedback-red: #ff2640;
    --ceres-feedback-red-text: #ff7687;
    --ceres-mat-select-background: #424242;
    --ceres-form-field-background: #f6f6f626;
    --ceres-slide-toggle-thumb: #bdbdbd;
    --ceres-slide-toggle-track: #ffffff80;
    --ceres-timesheets-header-background: #000228;
    --ceres-disabled-form-field-opacity: 0.06;
    --ceres-label-color: #ffffff99;
}
