// MAX WIDTH BASED ON MEDIA QUERIES
$max-width-s: 1100px;
$max-width-m: 100%;
$max-width-l: 100%; //1350px;
$max-width-xl: 1800px;

// GRID
$grid-12: 100%;
$grid-11: 91.66666667%;
$grid-10: 83.33333333%;
$grid-9: 75%;
$grid-8: 66.66666667%;
$grid-7: 58.33333333%;
$grid-6: 50%;
$grid-5: 41.66666667%;
$grid-4: 33.33333333%;
$grid-3: 25%;
$grid-2: 16.66666667%;
$grid-1: 8.33333333%;

$grid-distance: 30px;
$grid-distance-half: calc($grid-distance / 2);

// HEADER
$header-height: 90px;

// SCALE FACTOR -> screen size > 1920
$desktop-l-factor: 1.285;

// MENU & FAQ WIDTH
$menu-width: 520px;
$menu-width-big: $menu-width * $desktop-l-factor;

//TODO [STYLING] longterm remove
$breakpoints: (
    tablet: 'only screen and (min-width: #{0})',
    // adjust if site will be responsive
    desktop-s: 'print, only screen and (min-width: #{1024px})',
    desktop-m: 'print, only screen and (min-width: #{1366px})',
    desktop-l: 'print, only screen and (min-width: #{1920px})',
    desktop-xl: 'print, only screen and (min-width: #{2560px})'
);
