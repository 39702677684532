@use '@angular/material' as mat;
// Plus imports for other components in your app.

$custom-typography: mat.define-typography-config(
    $font-family: 'Siemens Sans',
    $headline-1: mat.define-typography-level(96px, 96px, 300, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $headline-2: mat.define-typography-level(60px, 60px, 300, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $headline-3: mat.define-typography-level(48px, 48px, 400, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $headline-4: mat.define-typography-level(34px, 40px, 400, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $headline-5: mat.define-typography-level(24px, 32px, 400, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $headline-6: mat.define-typography-level(20px, 32px, 500, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $subtitle-1: mat.define-typography-level(16px, 28px, 400, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $subtitle-2: mat.define-typography-level(14px, 22px, 500, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $body-1: mat.define-typography-level(14px, 20px, 400, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $body-2: mat.define-typography-level(14px, 20px, 400, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $caption: mat.define-typography-level(12px, 20px, 400, $font-family: 'Siemens Sans', $letter-spacing: normal),
    $button: mat.define-typography-level(14px, 14px, 500, $font-family: 'Siemens Sans', $letter-spacing: normal)
);
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies($custom-typography);
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat.define-palette(mat.$teal-palette, 500, 300, 700);
$app-accent: mat.define-palette(mat.$teal-palette, 500, 300, 700);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

$angular-dark-theme: mat.define-dark-theme(
    (
        color: (
            primary: mat.define-palette(mat.$teal-palette, A200, A100, A700),
            accent: mat.define-palette(mat.$teal-palette, A200, A100, A700),
            warn: $app-warn
        )
    )
);

[data-theme='dark'] {
    @include mat.all-component-colors($angular-dark-theme);
}

//references
//https://material.angular.io/guide/theming
//https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
