mat-header-cell {
    color: var(--ceres-primary-color-2);
    button {
        text-align: inherit;
    }
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: var(--ceres-primary-color-1);
}

.a-text-link-content {
    &:hover {
        border-bottom: none !important;
        color: var(--ceres-primary-color-1) !important;
    }
}

.no-link {
    cursor: auto;

    &:hover {
        color: var(--ceres-primary-color-1) !important;
    }
}

mat-header-cell,
mat-footer-cell,
mat-cell {
    font-size: 14px !important;

    span {
        font-size: 14px !important;
        max-width: 100%;
    }
}

.number-cell {
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.button-cell {
    display: flex;
    justify-content: center;
}

.table-container {
    &:not(.not-list):not(.not-services) {
        mat-row,
        mat-header-row,
        mat-footer-row {
            gap: 1.5rem;
        }
    }

    &:not(.no-padding) {
        mat-row,
        mat-header-row,
        mat-footer-row {
            display: flex;
            padding: 0 16px !important;
        }
    }

    mat-row {
        min-height: 65px;
        border-bottom: 1px solid var(--ceres-primary-color-7);
    }

    mat-header-row {
        height: 50px;
        background-color: var(--ceres-primary-color-7);
        color: var(--ceres-primary-color-3);
        border-bottom: 1px solid var(--ceres-primary-color-7);
    }

    .mat-sort-header-button {
        color: var(--ceres-primary-color-3);
        font-size: 14px;
    }
    &--projects-view {
        border: 0;
        height: auto !important;
        min-height: auto;
        mat-table {
            border: 1.5px solid var(--ceres-primary-color-8);
        }
    }
    overflow: auto;
    min-height: 500px;
    height: 65vh;
    max-height: 65vh;

    border: 1px solid var(--ceres-primary-color-7);

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    .a-text-link-content {
        color: var(--ceres-primary-color-1);
        font-weight: bold !important;
        font-size: 14px !important;

        &.inactive {
            color: var(--ceres-black) !important;
            cursor: default !important;
        }
    }

    mat-table {
        min-width: 100%;
    }

    &.not-list {
        mat-row {
            min-height: 50px;
        }
        mat-cell,
        mat-header-cell,
        mat-footer-cell {
            padding: 7px !important;
        }
    }
}

mat-header-cell,
mat-cell,
mat-footer-cell {
    padding: 0px;
    word-break: break-word;
}

mat-header-cell {
    align-self: stretch;
    display: flex;
    align-items: center;
    user-select: none;
    font-size: 13px !important;
    font-weight: 600;

    &.text-header-cell {
        justify-content: flex-start;
        text-align: left;

        .mat-sort-header-content {
            text-align: left;
        }
    }

    &.number-header-cell {
        justify-content: end;
        text-align: right;

        .mat-sort-header-content {
            text-align: right;
        }
    }

    &.button-header-cell {
        justify-content: center;
        text-align: center;

        .mat-sort-header-content {
            text-align: center;
        }
    }

    &.week-header {
        flex-direction: column;

        b {
            margin-bottom: 5px;
            font-size: 13px !important;
        }
    }

    span {
        font-size: 13px !important;
    }
}

mat-header-row {
    position: sticky;
    top: 0;
}

.pwo-label-with-select {
    display: flex;

    .pwo-label {
        margin-top: 7px;
        margin-bottom: 7px;
        user-select: none;
        height: 30px;
        max-height: 30px;
        font-size: 15px;
        font-family: 'Siemens Sans', sans-serif;
        display: flex;
        align-items: center;
        font-weight: 600;
        width: 25%;
        padding: 5px;
        background-color: var(--ceres-white);
        border-left: var(--ceres-primary-color-3) 1.5px solid;
        border-top: var(--ceres-primary-color-3) 1.5px solid;
        border-bottom: var(--ceres-primary-color-3) 1.5px solid;
        border-right: var(--ceres-primary-color-3) 1.5px solid;
        color: var(--ceres-primary-color-3);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .pwo-select {
        width: 75%;
    }
}

.pwo-input {
    height: 35px;
    max-height: 35px;
    display: flex;
    align-items: center;
    border: 1.5px solid var(--ceres-primary-color-8);
    background-color: var(--ceres-white);
    // margin-top: 7px !important;
    // margin-bottom: 7px !important;

    &--auto {
        min-width: 150px;
        display: inline-block;
        width: fit-content;
    }

    &.margins {
        margin: 0 7px;
    }

    input,
    textarea {
        height: 26px;
        width: 100%;
        border: none;
        padding: 10px;
        color: var(--ceres-secondary-color-16);
        font-family: 'Siemens Sans', sans-serif;
    }

    button {
        height: 26px;
        color: var(--ceres-secondary-color-16);

        &.highlighted {
            border: none !important;
            color: var(--ceres-white);
            background-color: var(--ceres-primary-color-3);

            &:hover {
                background-color: var(--ceres-primary-color-3);
            }
        }
    }

    .mat-icon-button {
        line-height: 26px;
    }
}

.pwo-form {
    height: 30px;
    max-height: 30px;
    display: flex;
    align-items: center;
    // border: 1.5px solid var(--ceres-primary-color-8);
    background-color: var(--ceres-white);
    //margin-bottom: 7px;
    // margin-top: 7px;
    margin-top: 15px;
    /*&.picker {
    border: 1px solid var(--ceres-primary-color-7);
  }*/

    &.mat-mdc-form-field-invalid {
        input {
            border: 1px solid var(--ceres-secondary-color-14) !important;
        }
    }

    input,
    textarea {
        position: absolute;
        top: 0px;
        font-weight: 400 !important;
        font-size: 16px !important;
        font-family: 'Siemens Sans', sans-serif !important;
        height: 30px !important;
        color: var(--ceres-primary-color-2);
        padding: 10px;
    }
    textarea:read-only,
    textarea:disabled,
    input:read-only,
    input:disabled {
        // background-color: var(--ceres-found-8);
        background-color: var(--ceres-primary-color-8);

        cursor: not-allowed;
        color: var(--ceres-primary-color-2);
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .mat-mdc-form-field-label {
        color: var(--ceres-primary-color-2);
        font-size: 16px;
    }
    .mat-mdc-form-field-label-wrapper {
        padding: 9px 6px 6px 6px;
    }
    .mat-mdc-form-field-underline {
        height: 0 !important;
        display: none;
    }

    .mat-mdc-form-field-wrapper {
        padding: 0 !important;
        width: 100%;
    }

    .mat-mdc-form-field-flex {
        align-items: center;
    }

    .mat-mdc-form-field-label-wrapper {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        padding: 6px;
    }

    .mat-mdc-form-field-infix {
        font-weight: 400 !important;
        font-size: 14px !important;
        font-family: 'Siemens Sans', sans-serif !important;
        padding: 0 !important; //TODO something is broken with the mat-form and paddings (Example project-information)
        height: 30px;
        border-top: 0 !important;

        label {
            margin-left: 3px;
            position: initial;
        }
    }

    &.mat-mdc-form-field-should-float {
        label {
            margin-left: -4px;
            margin-bottom: 3px;
        }
    }

    //TODO [STYLING] longterm remove
    .pwo-btn {
        position: absolute;
        right: 0;
        height: 30px;
        color: var(--ceres-secondary-color-16);

        &.highlighted {
            border: none !important;
            color: var(--ceres-white);
            background-color: var(--ceres-primary-color-1);

            &:hover {
                background-color: var(--ceres-primary-color-1);
            }
        }
    }

    .mat-icon-button {
        line-height: 26px;
    }
}

.pwo-form-textarea {
    display: flex;
    align-items: center;
    border: 1.5px solid var(--ceres-primary-color-8);
    background-color: var(--ceres-white);
    min-height: 40px;

    &.large {
        textarea {
            min-height: 150px;
            max-height: 250px;
            overflow: scroll;
        }
    }

    textarea {
        font-weight: 400 !important;
        font-size: 16px !important;
        color: var(--ceres-primary-color-2);
        font-family: 'Siemens Sans', sans-serif !important;
        padding: 10px !important;
        min-height: 40x;

        &:read-only {
            background-color: var(--ceres-primary-color-8);
            cursor: not-allowed;
        }
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .mat-mdc-form-field-underline {
        height: 0 !important;
        display: none;
    }

    .mat-mdc-form-field-wrapper {
        padding: 0 !important;
        width: 100%;
    }

    .mat-mdc-form-field-wrapper,
    .mat-mdc-form-field-flex,
    .mat-mdc-form-field-label-wrapper {
        height: auto !important;
    }

    .mat-mdc-form-field-label-wrapper {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        padding: 6px;
    }

    .mat-mdc-form-field-infix {
        font-weight: 400 !important;
        font-size: 14px !important;
        font-family: 'Siemens Sans', sans-serif !important;
        padding: 0 !important;
        border-top: 0 !important;
        height: auto;

        label {
            margin-left: 3px;
            position: initial;
            font-size: 16px;
            color: var(--ceres-primary-color-2);
        }
    }

    &.mat-mdc-form-field-should-float {
        label {
            margin-left: -4px;
            margin-bottom: 3px;
        }
    }
}

/*mat-select {
  &.margins {
    margin: 0 10px;
  }
}*/

.pwo-form {
    &--select {
        .mat-mdc-form-field-label {
            color: var(--ceres-primary-color-2);
            font-size: 16px;
        }
        .mat-mdc-form-field-label-wrapper {
            padding: 9px 6px 6px 6px;
        }
    }
}

mat-tab-group {
    &.projects {
        .mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-disabled {
            display: none !important;
        }
        .mat-mdc-tab-body-wrapper {
            padding: 20px 0px 20px 20px !important;
        }
        .mat-mdc-tab-label {
            min-width: 150px !important;
            max-width: 170px !important;
        }
        // .mat-mdc-tab-labels {
        //   width: 60% !important;
        // }
        .mdc-tab__content {
            .mdc-tab__text-label {
                font-family: 'Siemens Sans', sans-serif;
                font-size: 18px !important;
                color: var(--ceres-primary-color-2);
                padding: 0 !important;
            }
        }
        .mat-ink-bar {
            background-color: var(--ceres-primary-color-1) !important;
            height: 3px;
        }
        .mat-header {
            margin-top: 20px;
        }
    }
}

mat-tab-group[vertical] {
    flex-direction: row;

    mat-tab-header {
        flex-direction: column;
        border-bottom: 0px solid transparent;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        margin-top: 40px;

        .mat-mdc-tab-label-container {
            flex-direction: column;

            .mat-mdc-tab-labels {
                flex-direction: column;
            }

            mat-ink-bar {
                left: initial !important;
                bottom: initial;
                right: 0;
                width: 2px !important;
                height: initial;
            }
        }
    }

    .mat-mdc-tab-body-wrapper {
        flex-direction: column;
        width: 100%;
    }
}

.pwo-select {
    font-size: 15px;
    margin-top: 0px;
    background-color: var(--ceres-white);
    border: 1px solid var(--ceres-primary-color-7);
    height: 35px;
    max-height: 35px;
    padding: 7px 6px 6px 6px;
    span {
        color: var(--ceres-primary-color-2);
        font-size: 16px;
    }

    .mat-mdc-select-arrow {
        color: var(--ceres-primary-color-2);
    }

    &--auto {
        max-width: 175px;
        display: inline-block;
    }

    &--auto {
        max-width: 300px;
    }

    &.edit-project {
        text-align: left !important;
        &.calculation {
            background-color: var(--ceres-primary-color-7);
            span {
                font-size: 20px !important;
                color: var(--ceres-primary-color-1) !important;
            }
            .mat-mdc-select-arrow {
                color: var(--ceres-primary-color-1) !important;
                border-left: 10px solid transparent !important;
                border-right: 10px solid transparent !important;
                border-top: 10px solid !important;
            }
        }

        &.activity {
            height: 25px;
            padding: 3px 5px;
            position: relative;
            bottom: 4px;
            span {
                font-size: 12px;
            }
        }
    }

    &.notValid {
        border-color: var(--ceres-secondary-color-14);
        span {
            color: var(--ceres-black);
        }
    }

    &.mat-mdc-select-invalid {
        border-color: var(--ceres-secondary-color-14);

        .mat-mdc-form-field-label {
            color: var(--ceres-secondary-color-14) !important;
        }
    }

    &.mat-mdc-select-required {
        .mat-mdc-select-placeholder:after {
            content: ' *';
        }
    }
}

::ng-deep .mat-mdc-checkbox .mat-mdc-checkbox-frame {
    border-color: violet;
    background-color: var(--ceres-secondary-color-14) !important;
}

button {
    &.pwo {
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        margin-bottom: 7px !important;
        margin-top: 7px !important;

        &--mb {
            margin-bottom: 10px;
        }

        cursor: pointer;
        display: flex;
        width: 100%;
        height: 35px;
        max-height: 35px;
        justify-content: space-between;
        align-items: center;

        > .container {
            display: flex;
            align-items: center;
            padding: 0 !important;
        }

        &.dd-with-icon {
            background-color: var(--ceres-primary-color-1);
            color: var(--ceres-white);
            &:hover {
                background-color: var(--ceres-primary-color-1);
            }

            .icon {
                margin-right: 7px;
            }

            .icon,
            span {
                font-size: 13px;
            }
        }

        &.smaller {
            max-height: 30px;

            > .container {
                padding: 0;
            }
        }

        &--with-icon {
            background-color: var(--ceres-white);

            color: var(--ceres-primary-color-1);
            border: 2px solid var(--ceres-primary-color-1);

            .container {
                padding: 0 !important;
            }

            .icon {
                margin-right: 7px;
            }

            span {
                font-size: 13px;
            }

            &:hover {
                background-color: var(--ceres-primary-color-1);
                color: var(--ceres-white);
            }

            &:disabled {
                color: gray;
                border: 2px solid gray;

                &:hover {
                    background-color: gray;
                    color: var(--ceres-white);
                }
            }
        }

        &--150 {
            display: inline-block;
            width: 150px;
        }

        &--auto {
            min-width: 150px;
            display: inline-block;
            width: fit-content;
        }

        &.margins {
            margin: 0 10px;
        }

        &.smaller-text {
            span {
                font-size: 13px !important;
            }
        }

        &--last {
            margin-right: 0 !important;
        }
    }
}

.grid-col.button-container {
    display: flex;
    gap: 7px;
    // padding: 7px 0;

    :first-child {
        margin-left: 0 !important;
    }

    :last-child {
        margin-right: 0 !important;
    }

    &--right-normal {
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
    }

    &--right {
        .burger {
            margin-top: 7px !important;
            margin-bottom: 7px !important;
            visibility: hidden;

            &:before {
                margin: 0 !important;
            }
        }

        @media only screen and (max-width: 1225px) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 0;
            max-height: 44px;
            z-index: 101;

            &.optional {
                display: none;
            }

            button,
            mat-select,
            ceres-button {
                min-width: 250px !important;
                max-width: 250px !important;
                margin: 3px 0 3px 0 !important;
                visibility: hidden;
                transition: visibility 0.15s ease-in-out;

                &.triggered {
                    visibility: visible;

                    & > * {
                        visibility: visible;
                        margin: 0 !important;
                    }
                }

                &.optional2 {
                    display: none;
                }
            }

            button {
                &.optional {
                    display: flex;
                }
            }

            mat-select {
                &.optional {
                    display: inline-block;
                }
            }

            .burger {
                visibility: visible;
                min-width: auto !important;
            }
        }

        @media only screen and (min-width: 1226px) {
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;

            button {
                &.optional {
                    display: none;
                }
            }

            mat-select {
                &.optional {
                    display: none;
                }
            }
        }
    }

    button {
        :last-of-type {
            margin-right: 0 !important;
        }
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-container {
    height: calc(100vh - 118px);

    &.overlay {
        height: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--ceres-primary-color-8);
        z-index: 999;
        height: auto;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--ceres-secondary-color-1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--ceres-secondary-color-1);
}

mat-chip {
    background-color: var(--ceres-form-field-background);
    border-radius: 0 !important;
    padding: 4px;
    height: fit-content;

    mat-icon {
        color: var(--ceres-primary-color-1);
        opacity: 1;
    }

    &:hover,
    &:active {
        filter: brightness(0.9);
        color: var(--ceres-primary-color-1) !important;
        opacity: 1;
        cursor: pointer;

        mat-icon {
            color: var(--ceres-primary-color-1) !important;
            opacity: 1;
        }
    }
}

.form-container {
    .options-form {
        padding: 14px 14px 0px 14px;
        background-color: var(--ceres-primary-color-7);
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 0 !important;
        }

        &--header {
            margin-bottom: 0;
            border-bottom: 1px solid var(--ceres-black);
            padding: 7px;
            background-color: var(--ceres-primary-color-3);

            p,
            b {
                color: var(--ceres-static-white) !important;
            }
        }
    }
}

.mat-mdc-checkbox-checked,
.mat-mdc-checkbox-indeterminate {
    .mat-mdc-checkbox-background {
        background-color: var(--ceres-primary-color-1) !important;
    }
}

.mat-ink-bar {
    background-color: var(--ceres-primary-color-3) !important;
}

.mat-pseudo-checkbox-checked {
    background-color: var(--ceres-primary-color-1) !important;
}

.mat-mdc-checkbox.mat-mdc-checkbox-semichecked {
    .mat-mdc-checkbox-background {
        z-index: 1;
        background-color: var(--ceres-primary-color-3);
        margin: 4px;
    }

    .mat-mdc-checkbox-frame {
        z-index: 2;
        border-color: var(--ceres-black-opaque-54);
    }
}

.mat-mdc-checkbox-touch-target {
    height: 18px;
    width: 18px;
}

.pwo-checkbox {
    height: 44px;
    display: flex !important;
    align-items: center;
}

.mat-mdc-footer-row {
    border-top: 1.5px solid var(--ceres-primary-color-6);
    background-color: var(--ceres-primary-color-8);
}

.pwo-no-underline {
    .mat-mdc-form-field-wrapper {
        padding-bottom: 0 !important;
    }

    .mat-mdc-form-field-underline {
        display: none;
    }

    .mat-mdc-form-field-label {
        color: var(--ceres-primary-color-2);
        font-size: 16px;
    }

    .mat-mdc-form-field-infix {
        font-weight: 400 !important;
        font-size: 14px !important;
        font-family: 'Siemens Sans', sans-serif !important;

        label {
            margin-left: 3px;
        }
    }
}

.space-checkbox {
    display: flex;
    height: 44px;
    align-items: center;

    mat-checkbox {
        width: 100%;
    }

    .mat-mdc-checkbox-layout {
        justify-content: flex-start;
        width: 100%;
    }

    .mat-mdc-checkbox-inner-container {
        margin: 0 10px 0 0 !important;
    }
}

.cursored {
    cursor: pointer;
}

.button-menu-container {
    background: transparent !important;
    box-shadow: none;

    button {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
}

.space {
    min-height: 1px;
}

.month-row {
    margin-bottom: 30px !important;
    justify-content: center;
    display: flex;

    .month-title {
        p {
            font-weight: bold !important;
        }
    }
}

.paginator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    button {
        padding: 0;
        width: 24px;
        height: 24px;
        outline: none;
        background-color: transparent;
        cursor: pointer;
    }

    mat-select {
        height: fit-content;
        width: 150px;
    }
}

.header {
    min-height: 44px;
    display: flex;
    align-items: center;
}
.mat-calendar-body-selected {
    background-color: var(--ceres-primary-color-1);
}
.mat-datepicker-toggle-active {
    color: var(--ceres-primary-color-1);
}

ceres-multi-select {
    cursor: pointer;
    position: absolute;
    top: 0px;
    z-index: 100;
    right: 15px;
    background-color: var(--ceres-primary-color-1);
    height: 57px;
    width: 50px;
    color: var(--ceres-white);

    &.column-select-filter {
        margin-right: -2px;
    }

    &.no-right {
        right: 0;
        margin-right: 0 !important;
    }

    > .trigger {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        align-items: center;
    }
}
.top-row {
    margin-top: 30px;
}

.main-container-spinner {
    margin-top: 25px;
    height: calc(100vh - 196px);
    display: flex;
    align-items: center;
}

.main-container-spinner-50 {
    margin-top: 50px;
}

.main-spinner {
    margin: 0 auto;
}

.date-warning {
    font-size: 14px;
    color: orange;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
    background-color: var(--ceres-primary-color-1) !important;
    color: var(--ceres-white);
}

// NEW CODE SECTION (USED AND REVIEWED)
// Note: this section will be only exist in short term due cleaning up dead css code

.pwo-tooltip {
    font-size: 14px;
    color: var(--ceres-white);
}

//TODO refactor dialog headers to default mat-headers
.dialog-header {
    display: flex;
    align-items: center;
    padding: 7px;
    margin: -14px -22px 12px;

    p,
    b,
    h4 {
        color: var(--ceres-black) !important;
    }

    .left,
    .right {
        display: flex;
    }

    .left {
        justify-content: flex-start;
    }

    .right {
        justify-content: flex-end;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            cursor: pointer;
            color: var(--ceres-black);

            i {
                font-size: 20px;
            }
        }
    }
}

// OVERRIDING MATERIAL CODE
.mat-custom {
    &.mat-mdc-form-field-appearance-fill .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
    &.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-label,
    &.mat-mdc-form-field-appearance-fill .mat-mdc-select-value-text,
    &.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
        color: var(--ceres-input);
    }

    &.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex {
        background-color: rgba(246, 246, 246, var(--ceres-disabled-form-field-opacity)) !important;
    }

    &.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex input,
    &.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-label {
        color: var(--ceres-input);
    }

    &.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable {
        .mat-mdc-form-field-flex {
            background-color: rgba(246, 246, 246, var(--ceres-disabled-form-field-opacity)) !important;
        }

        .mat-mdc-form-field-underline {
            height: 0 !important;
            display: none;
        }
    }

    &.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex {
        background-color: var(--ceres-input-background);

        & mat-icon {
            color: var(--ceres-primary-color-1);
        }

        &:has(input[disabled]),
        &:has(textarea[disabled]) {
            background-color: var(--ceres-primary-color-8);
            cursor: not-allowed;
            input {
                cursor: not-allowed !important;
            }
        }

        &:has(input[readonly]),
        &:has(textarea[readonly]) {
            cursor: not-allowed;
            input {
                cursor: not-allowed !important;
            }
        }
    }

    &.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
    &.mat-mdc-form-field-invalid .mat-placeholder-required,
    &.mat-mdc-form-field-invalid .mat-mdc-form-field-label {
        color: var(--ceres-secondary-color-14) !important;
    }

    &.mat-mdc-form-field-invalid .mat-mdc-form-field-underline .mat-mdc-form-field-ripple {
        background: var(--ceres-secondary-color-14) !important;
    }
}

mat-table.mat-custom {
    mat-footer-cell button mat-icon,
    mat-cell button mat-icon {
        color: var(--ceres-input);
    }
}

mat-checkbox {
    &.mat-mdc-checkbox-disabled {
        cursor: not-allowed;
    }
}

.mat-fix-padding {
    margin-bottom: -1.25em;
}

.multiline-tooltip {
    white-space: pre-line !important;
}

mat-calendar-header {
    color: var(--ceres-black);
}

.mat-custom-spinner {
    &:disabled {
        cursor: not-allowed !important;
    }

    .mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: var(--ceres-white) !important;
    }
}

// MATERIAL v17 OVERRIDE STYLES

.mat-mdc-form-field {
    --mat-form-field-container-height: 32px;
    --mat-form-field-filled-with-label-container-padding-top: 12px;
    --mat-form-field-filled-with-label-container-padding-bottom: 12px;
    --mdc-filled-text-field-label-text-size: 16px;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: 'Siemens Sans', sans-serif;
    letter-spacing: normal;

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
        border-radius: 4px 4px 0 0;
        padding: 0.75em 0.75em 0 0.75em;
    }

    // Conditional padding for mat-select elements
    &.mat-mdc-form-field-type-mat-select {
        --mat-select-trigger-text-size: 16px;
        --mat-form-field-filled-with-label-container-padding-top: 8px !important; // Adjusted padding
        --mat-form-field-filled-with-label-container-padding-bottom: 8px !important; // Adjusted padding
    }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--ceres-form-field-background) !important;
}

/* Override for chip grid/set fields */
.mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid
    .mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-dialog-container
    .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid
    .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-overlay-container
    .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid
    .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-drag-preview
    .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

 //form field appearance="outline" height
.mdc-text-field--outlined:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix,
.mdc-text-field--no-label:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix {
  --mat-form-field-container-vertical-padding: 6px;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

/* Make chip grids/sets transparent */
.mat-mdc-chip-grid.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-chip-set.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

/* Style for disabled text fields */
.mdc-text-field--disabled,
.mdc-text-field--filled.mdc-text-field--disabled,
.mdc-text-field--outlined.mdc-text-field--disabled,
.mat-mdc-form-field-disabled {
    background-color: rgba(246, 246, 246, var(--ceres-disabled-form-field-opacity)) !important;
    color: var(--ceres-black) !important;
}

.mdc-text-field--disabled .mdc-text-field__input:not(.link) {
    color: var(--ceres-black) !important;
}

/* For labels specifically */
.mdc-text-field--disabled .mdc-floating-label,
.mat-mdc-form-field-disabled .mat-mdc-form-field-label {
    color: var(--ceres-black) !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: rgba(246, 246, 246, var(--ceres-disabled-form-field-opacity));
}

.mat-mdc-radio-button .mdc-radio,
.mdc-checkbox {
    padding: 0;
    margin: 0;
}

.mdc-checkbox .mdc-checkbox__background {
    top: unset;
    left: unset;
}

.mat-mdc-option .mat-icon,
.mat-mdc-option .mat-pseudo-checkbox-full,
.mdc-checkbox {
    margin-right: 8px;
}

.mat-mdc-option {
    --mat-option-label-text-size: 14px;
}

.mat-mdc-table {
    background-color: transparent;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
    display: block;
    padding: 24px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
}

.content-container-title {
    color: var(--ceres-black);
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    color: var(--ceres-white) !important;
}

.cdk-virtual-scroll-content-wrapper {
    display: flex;
    flex-direction: column;
}

.mat-mdc-cell,
.mat-mdc-header-cell {
    border-bottom-style: none !important;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0 !important;
    color: var(--ceres-primary-color-1);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: var(--ceres-black);
}

.mdc-evolution-chip__cell {
    margin: 0 4px;
}

.mat-mdc-menu-item-text {
    --mat-menu-item-label-text-size: 14px;
}

.mat-mdc-cell:not(.services) {
    .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 24px;
    }
}

.mat-mdc-standard-chip .mdc-evolution-chip__text-label i {
    color: var(--ceres-primary-color-1);
}

.mat-mdc-cell {
    .mat-mdc-input-element {
        color: var(--ceres-black);
        background-color: transparent;
        border: none;
    }
}

.mat-mdc-form-field-icon-prefix {
    color: var(--mdc-filled-text-field-input-text-color);
}

// position the icon suffix in the mat-datepicker toggle correctly to make the input smaller

mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 24px;
  transform: translate(-25%, 7.5%);
  padding: 0 !important;
}

mat-datepicker-toggle .mat-datepicker-toggle-default-icon, mat-datepicker-toggle .mat-mdc-button-touch-target {
  width: 24px;
  height: 24px;
}

mat-datepicker-toggle .mat-mdc-focus-indicator, mat-datepicker-toggle .mat-mdc-focus-indicator {
  width: 24px;
  height: 24px;
}

.mat-mdc-icon-button .mat-mdc-paginator-icon {
    fill: var(--ceres-black);
}

.month-selector {
    min-width: 200px;
}

.mat-mdc-standard-chip .mdc-evolution-chip__text-label {
    color: var(--ceres-primary-color-1);
    opacity: 1;
    border-radius: 0 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.mat-mdc-chip-trailing-icon {
    padding: 0;
}

.mdc-evolution-chip__text-label {
    white-space: pre-wrap;
    height: fit-content;
}

.mdc-tooltip__surface {
  background-color: var(--ceres-primary-color-1) !important;
  color: var(--ceres-white) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: var(--ceres-label-color);
}
