a {
    &.mat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.mat-list-item {
        &:hover {
            color: currentColor;
        }
    }
}

input,
button,
select,
optgroup,
textarea {
    line-height: normal;
}
