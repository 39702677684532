*,
*:before,
*:after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li {
    margin: 0;
    padding: 0;
}

img {
    width: 100%;
}

ol {
    margin: 0;
    padding: 0;

    li {
        @include copy-txt(var(--ceres-primary-color-3));
    }
}

ul {
    margin: 0;
    padding: 0;

    li {
        @include copy-txt(var(--ceres-primary-color-3));
    }
}

a {
    cursor: pointer;
    text-decoration: none;
}

button {
    border: none;
    outline: none;
}

//TODO move or clean up
ceres-project-relations-container.child-container {
    //first-child also last-child, first-child with no children
    &:first-child:last-child,
    &:first-child:nth-last-child(1) {
        &.relation-lines {
            border-top: 0 !important;
        }

        &.relation-lines:after {
            height: 0 !important;
        }
    }

    //first child with children
    &:first-child:not(:nth-last-child(1)) {
        &.relation-lines {
            border-top: 2px solid var(--ceres-primary-color-5);
            position: relative;
        }

        &.relation-lines:after {
            padding: 0;
            margin: 0;
            display: block; /* probably not really needed? */
            content: '';
            width: 49.75%;
            height: 2px;
            background-color: var(--ceres-primary-color-7);
            position: absolute;
            left: 0;
            top: -2px;
        }
    }

    //all children without the last-child
    &:not(:first-child):not(:last-child) {
        &.relation-lines {
            border-top: 2px solid var(--ceres-primary-color-5);
            position: relative;
        }
    }

    //last-child if more than one element exists
    &:not(:first-child):last-child {
        &.relation-lines {
            border-top: 2px solid var(--ceres-primary-color-5);
            position: relative;
        }

        &.relation-lines:after {
            padding: 0;
            margin: 0;
            display: block; /* probably not really needed? */
            content: '';
            width: 49.75%;
            height: 2px;
            background-color: var(--ceres-primary-color-7);
            position: absolute;
            right: 0;
            top: -2px;
        }
    }
}

.tile-container {
    cursor: pointer;
    width: 100%;
    height: 235px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tile-content {
        width: 100%;
        height: 100%;
        -webkit-box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
        -moz-box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
        box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
        background: var(--ceres-primary-color-3);
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--ceres-static-white);
        overflow: hidden;

        .tile-icon {
            text-align: center;
            width: 100%;
            display: inline-block;
            padding: 0;
            .card-title {
                color: var(--ceres-static-white);
            }

            i {
                font-size: 48px;
            }

            .caption {
                display: none;
                font-size: 14px !important;
                padding: 0 20px !important;
                color: var(--ceres-static-white);
            }
        }

        &:hover {
            .tile-icon {
                width: 100%;
            }

            .icon.a-icon {
                animation-name: slideOutLeft;
                animation-duration: 0.6s;
                animation-fill-mode: both;

                &::before {
                    animation-name: resize;
                    animation-duration: 0.6s;
                    animation-fill-mode: both;
                }
            }

            .icon.material-icons {
                animation-name: slideOutLeft, resize;
                animation-duration: 0.6s, 0.6s;
                animation-fill-mode: both, both;
            }

            @keyframes slideOutLeft {
                0% {
                    transform: translateY(0);
                }

                100% {
                    transform: translateY(-100%);
                }
            }

            @keyframes resize {
                0% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(0.7);
                }
            }

            h4 {
                -webkit-animation-name: slideOutUp;
                animation-name: slideOutUp;
                -webkit-animation-duration: 0.4s;
                animation-duration: 0.4s;
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
            }

            @-webkit-keyframes slideOutUp {
                0% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                }

                100% {
                    -webkit-transform: translateY(-30%);
                    transform: translateY(-30%);
                }
            }

            @keyframes slideOutUp {
                0% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                }

                100% {
                    -webkit-transform: translateY(-30%);
                    transform: translateY(-30%);
                }
            }

            .caption {
                display: block !important;
                width: 100%;
                float: left;
                text-align: left;
                -webkit-animation-name: fadeIn;
                animation-name: fadeIn;
                -webkit-animation-duration: 0.4s;
                animation-duration: 0.4s;
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
            }

            @-webkit-keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }

        @keyframes Wait-And-Start {
            from {
                transform: translateY(0);
            }

            to {
                transform: translateY(200%);
            }
        }
    }

    mat-card-actions {
        padding-top: 0 !important;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        font-size: small;
    }
}

.spacer-page {
    padding-bottom: 64px + 28px;
}
