footer {
    .nav {
        ul {
            list-style: none;

            li {
                float: left;
            }
        }
    }
}

.footer-text {
    @include footer-txt(var(--ceres-primary-color-5));
    cursor: default;
    margin: 0 9px;
}

@media only screen and (max-width: 768px) {
    .nav ul li,
    info {
        margin: 0 4px !important;
    }

    .footer-text {
        font-size: 10px !important;
    }
}

@media only screen and (max-width: 576px) {
    footer {
        padding: 8px !important;
    }

    .nav ul li,
    info {
        margin: 0 2px !important;
    }

    .footer-text {
        font-size: 8px !important;
    }
}
