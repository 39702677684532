.menu-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    > li {
        color: var(--ceres-primary-color-5);

        > a {
            .head-nav-icon {
                display: flex;
                justify-content: center;
                width: 60px;
                font-size: 36px;
                text-align: center;
                color: var(--ceres-primary-color-5);
            }
            .head-nav-icon:hover {
                color: var(--ceres-primary-color-1);
            }
        }
    }

    a {
        display: block;
        height: 100%;
        color: var(--ceres-static-white);
        text-decoration: none;
        cursor: pointer;
    }
}

.icon {
    &::before {
        font-family: 'siemens-webicons-webfont', sans-serif !important;
        display: inline-block;
        text-align: center;
        text-transform: none;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        speak: none;
        -webkit-font-smoothing: antialiased;
    }
}
