@mixin button($type) {
    @include fontstack(siemens-sans-bold, 14px, 0, 0, null, 400);
    outline: none;
    transition:
        color 0.15s ease-in-out,
        background 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    @include flex-center();
    text-align: left;
    cursor: pointer;
    justify-content: flex-start;
    padding: 9px 15px 9px 10px;
    min-height: 35px;
    border-radius: 0;
    line-height: normal;
    position: relative;

    &.a-icon {
        &:before {
            font-size: 18px;
            line-height: normal !important;

            margin: 0 10px 0 0;
            transition: color 0.15s ease-in-out;
            display: inline-block;
            transform: translate(0, -1px);
        }
    }

    @if $type == pwo {
        height: 35px;
        font-size: 14px !important;
        font-weight: 600;
        text-align: center;
        padding: 7px 20px;
        display: flex;
        justify-content: center;
        background-color: var(--ceres-primary-color-1);
        color: var(--ceres-white);
        //margin: 0 7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            background: var(--ceres-primary-color-1);
            color: var(--ceres-white);
        }

        &:disabled {
            &:hover {
                background: var(--ceres-primary-color-1);
            }
        }
    }

    @if $type == cancel {
        margin: 0 7px;
        height: 35px;
        font-size: 14px !important;
        font-weight: 600;
        text-align: center;
        padding: 7px 20px;
        background-color: var(--ceres-white);
        color: var(--ceres-primary-color-5);
        border: 1px solid var(--ceres-primary-color-5) !important;
        font-weight: 500;
        justify-content: center;
        &:hover {
            background-color: var(--ceres-primary-color-5);
            color: var(--ceres-white);
        }
    }

    @if $type == warning {
        margin: 0 7px;
        height: 35px;
        font-size: 14px !important;
        font-weight: 600;
        text-align: center;
        padding: 7px 20px;
        background-color: var(--ceres-secondary-color-12);
        color: var(--ceres-white);
        border: none !important;
        font-weight: 500;
        justify-content: center;
        &:hover {
            background-color: var(--ceres-secondary-color-12);
        }
    }

    @if $type == danger {
        margin: 0 7px;
        height: 35px;
        font-size: 14px !important;
        font-weight: 600;
        text-align: center;
        padding: 7px 20px;
        background-color: var(--ceres-secondary-color-14);
        color: var(--ceres-white);
        border: none !important;
        font-weight: 500;
        justify-content: center;
        &:hover {
            background-color: var(--ceres-secondary-color-14);
        }
    }
}
