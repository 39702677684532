@charset "utf-8";

//TODO
// Prework: remove as many colors as possible
// STEP1: REMOVE UNUSED / OBSOLETE SCSS
// STEP2: CHANGE GO THROUGH THE VIEWS UNTIL OLD SCSS IS OBSOLETE
// STEP3: CLEAN UP CODEBASE UNTIL EVERYTHING IS CLEAN - STEP 2

@import 'bootstrap/custom-bootstrap.scss';

@import 'globals/colors';
@import 'sms-theme'; //material design
@import 'globals/icons';
@import 'globals/typography';
@import 'globals/mixins';

@import 'globals/variables'; //TODO needs to stay here because of variables issue - needs to be analysed
@import 'globals/buttons'; //TODO [STYLING] longterm remove
@import 'globals/flex';

#sie-boilerplate,
.mat-mdc-dialog-container,
.cdk-overlay-container,
.cdk-drag-preview {
    @import 'globals/pwo';
    @import 'globals/base'; //TODO [STYLING] longterm merge with custom changes
    @import 'layouts/lay-wrapper'; //TODO [STYLING] longterm remove
    @import 'components/footer';
    @import 'modules/mod-nav';
    @import 'atoms/a-text'; //TODO [STYLING] longterm remove
    @import 'atoms/a-icon'; //TODO [STYLING] longterm remove
    @import 'atoms/a-buttons'; //TODO [STYLING] longterm remove
    @import 'atoms/menu-buttons';
    @import 'components/header';
    @import 'components/nav';
    @import 'modules/mod-navigation';
    @import 'modules/mod-logo';
}

//##########################
//CUSTOM CHANGES
//##########################
html,
body {
    height: 100%;
}

html {
    width: 100%;
}

body {
    background: var(--ceres-white);
    margin: 0;
    display: flex;
    flex-direction: column;
    font-family: 'Siemens Sans', sans-serif !important;

    .mat-mdc-card,
    .mat-mdc-select {
        font-family: 'Siemens Sans', sans-serif !important;
    }

    //TODO [STYLING] longterm remove
    &.new-wrapper {
        .lay-wrapper--content {
            // top: 90px !important;
            padding-top: 90px;
            padding-left: 0px !important;
            margin-left: 0px !important;
            padding-bottom: 30px;
            /* width: 95% !important; */
            width: 100% !important;
            padding-right: 60px !important;
        }
    }

    //TODO [STYLING] longterm remove
    &.projects {
        background-color: var(--ceres-primary-color-7);

        .lay-wrapper--content {
            top: 90px !important;
            padding-top: 0px !important;
            padding-left: 0px !important;
            margin-left: 0px !important;
            /* width: 95% !important; */
            width: 100% !important;
            padding-right: 60px !important;
        }
    }
}

.filter-container {
    margin-bottom: 12px;
    padding: 32px;
    background-color: var(--ceres-primary-color-7);
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
        min-height: 34px;
        color: var(--ceres-primary-color-2);
        font-size: 20px;
        line-height: 24px;
        border-bottom: 2px solid var(--ceres-primary-color-1);
    }

    .search-container {
        width: 100%;
        max-width: 250px;
        position: relative;
        padding-top: 8px;

        input {
            padding: 0 30px 10px 0;
            background: transparent;
            border: none;
            border-bottom: 2px solid var(--bs-gray-500);
            width: 100%;
            color: var(--ceres-input);
        }

        input:focus {
            outline: none;
            border-bottom: 2px solid var(--ceres-primary-color-1);
        }

        .icon {
            position: absolute;
            right: 5px;
        }
    }
}

.inline-navbar {
    .navbar-nav-item {
        background-color: var(--ceres-primary-color-7);
        color: var(--ceres-primary-color-2);
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
        border-bottom: 2px solid var(--bs-gray-500);

        &.active {
            border-bottom: 2px solid var(--ceres-primary-color-1);
        }

        &:hover {
            cursor: pointer;
            filter: brightness(0.95);
        }
    }

    .navbar-actions {
        background-color: var(--ceres-primary-color-7);
        border-bottom: 2px solid var(--ceres-primary-color-1);

        &:hover {
            cursor: pointer;
            filter: brightness(0.95);
        }
    }
}

.default-title {
    min-height: 34px;
    color: var(--ceres-primary-color-2);
    font-size: 20px;
    line-height: 24px;
    border-bottom: 2px solid var(--ceres-primary-color-1);
}

.search-container-standalone {
    width: 100%;
    max-width: 250px;
    position: relative;

    input {
        padding: 0 30px 10px 0;
        background: transparent;
        border: none;
        border-bottom: 2px solid var(--bs-gray-500);
        width: 100%;
    }

    input:focus {
        outline: none;
        border-bottom: 2px solid var(--ceres-primary-color-1);
    }

    .icon {
        position: absolute;
        right: 5px;
    }
}

//TODO remove long term
.list-filter-row {
    min-height: 60px;
    // background-color: var(--ceres-primary-color-7);
    // padding: 15px 0;
    // margin-bottom: 30px;
    // .list-container {
    //   padding: 0 15px;
    // }

    .title {
        color: var(--ceres-primary-color-2);
        font-size: 24px;
    }

    .button-container {
        height: 50px;
        justify-content: flex-end;
        display: flex;
        padding-right: 0 !important;
    }

    .search-container {
        margin-right: auto;
        width: 300px;
    }
}

//TODO [STYLING] longterm remove
.list-container {
    //width: 95%;
    margin-left: auto;
    margin-right: auto;
}

//TODO [STYLING] longterm remove
.list-container-tiles {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

/* Disbaled button */
button:disabled {
    cursor: default !important;
    opacity: 0.6;
}

[ng-reflect-router-link] {
    cursor: pointer;
}

.mat-mdc-checkbox-checked,
.mat-mdc-checkbox-indeterminate {
    .mat-mdc-checkbox-background {
        background-color: var(--ceres-primary-color-3) !important;
    }
}

.mat-pseudo-checkbox-checked,
.mat-ink-bar {
    background-color: var(--ceres-primary-color-3) !important;
}

.mat-mdc-checkbox.mat-mdc-checkbox-semichecked {
    .mat-mdc-checkbox-background {
        z-index: 1;
        background-color: var(--ceres-primary-color-3);
        margin: 4px;
    }

    .mat-mdc-checkbox-frame {
        z-index: 2;
        border-color: var(--ceres-black-opaque-54);
    }
}

#contentRow {
    #sideNavBox {
        display: none;
    }

    #contentBox {
        margin-left: 0;
    }
}

// .mat-dialog-content {
//   max-height: 80vh !important;
// }

.mat-mdc-form-field span {
    overflow: visible;
}

.mat-mdc-tooltip.bigger {
    font-size: 1rem;
}

#sie-boilerplate,
.mat-mdc-dialog-container {
    .mat-mdc-header-row {
        position: sticky;
        top: 0;
        background: var(--ceres-white);
        z-index: 100;
    }

    .mat-column-MPNumber {
        flex-basis: 2.5rem;
    }

    mat-header-cell {
        .mat-sort-header-container {
            display: inline-flex;
        }

        &.filter-active {
            color: var(--ceres-primary-color-1);
        }

        &.pull-right {
            float: right;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            text-align: right;
        }
    }

    //TODO remove when widt: 95% is removed
    ceres-collapse-item mat-form-field.mat-custom .mat-mdc-form-field-wrapper {
        width: 100% !important;
    }

    mat-cell,
    mat-footer-cell {
        .mat-mdc-form-field-wrapper {
            width: 95%; //TODO needs to be removed
        }

        .mat-mdc-form-field-infix {
            width: auto;
        }

        .mat-mdc-checkbox-layout {
            display: table-row;
        }

        .pull-right {
            float: right;
            padding-right: 1.2rem;
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .center-checkbox {
            display: block;
            text-align: center;
        }
    }
}

pwo-filter {
    button {
        cursor: pointer !important;
    }
}

.cdk-overlay-pane {
    max-width: 100% !important;

    mat-dialog-content {
        max-height: 100%;
    }
}

.mat-mdc-button-toggle-label-content {
    line-height: 28px !important;
}

ceres-partial-occupation-chart {
    .mat-mdc-select {
        background-color: var(--ceres-primary-color-4);
        height: 35px;

        &:hover {
            background-color: var(--ceres-secondary-color-6);
            color: var(--ceres-white);

            .mat-mdc-select-value-text {
                color: var(--ceres-white);
            }
        }

        .mat-mdc-select-value {
            padding: 10px !important;
        }

        .mat-mdc-select-value-text {
            font-size: 13px !important;
        }

        .mat-mdc-select-arrow {
            margin: 0px 10px 0px 4px;
        }

        i {
            padding: 5px 10px 0px 0px;
        }
    }
}

ceres-gms-reporting {
    .mat-mdc-card-header-text {
        margin: 0px !important;
    }
}

.mat-mdc-progress-bar-fill::after {
    background-color: var(--ceres-secondary-color-5);
}

.mat-mdc-progress-bar-buffer {
    background: var(--ceres-primary-color-7);
}

.mat-mdc-progress-bar {
    border-radius: 2px;
}

.mat-mdc-progress-bar-background {
    fill: none;
}

.label {
    font-size: 12px;
    color: var(--ceres-primary-color-5);

    &.notValid {
        color: var(--ceres-secondary-color-14);
    }
}

.mat-mdc-card {
    background: transparent;
}

.mod-header {
    width: 92.5%;
    display: block;
    transition: max-width 0.25s ease-in-out;
}

.custom-dialog {
    mat-dialog-container {
        padding: 0;
        overflow: auto;
        background: var(--ceres-black-opaque-0);
        box-shadow: unset;
    }
}

.content-form-container {
    .pwo-form .mat-mdc-form-field-label-wrapper {
        box-sizing: border-box !important;
    }
}

.templates-spinner-container circle {
    stroke: var(--ceres-white) !important;
}

.offerTemplateTooltip {
    font-size: 15px !important;
}

mat-tree-node mat-checkbox.mat-mdc-checkbox label.mat-mdc-checkbox-layout span.mat-mdc-checkbox-label {
    bottom: 2px;
    left: 12px;
    position: relative;
}

mat-tree-node mat-checkbox.mat-mdc-checkbox span.mat-mdc-checkbox-inner-container {
    margin-top: 8px;
}

//TODO remove only used in main
.nav-list {
    width: 90%;

    .nav-tile {
        cursor: pointer;

        mat-card {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            mat-card-content {
                width: calc(100% - 50px);
                height: calc(100% - 50px);
                -webkit-box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
                -moz-box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
                box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
                background: var(--ceres-primary-color-3);
                background-size: contain;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--ceres-white);
                overflow: hidden;

                .tile-icon {
                    text-align: center;
                    width: 100%;
                    display: inline-block;
                    padding: 0;
                    h4 {
                        color: var(--ceres-white) !important;
                    }

                    i {
                        font-size: 3.5vw;
                    }

                    .caption {
                        display: none;
                        font-size: 14px !important;
                        padding: 0 20px !important;
                        color: var(--ceres-white) !important;
                    }
                }

                &:hover {
                    .tile-icon {
                        width: 100%;
                    }

                    .icon.a-icon {
                        animation-name: slideOutLeft;
                        animation-duration: 0.6s;
                        animation-fill-mode: both;

                        &::before {
                            animation-name: resize;
                            animation-duration: 0.6s;
                            animation-fill-mode: both;
                        }
                    }

                    .icon.material-icons {
                        animation-name: slideOutLeft, resize;
                        animation-duration: 0.6s, 0.6s;
                        animation-fill-mode: both, both;
                    }

                    @keyframes slideOutLeft {
                        0% {
                            transform: translateY(0);
                        }

                        100% {
                            transform: translateY(-100%);
                        }
                    }

                    @keyframes resize {
                        0% {
                            transform: scale(1);
                        }

                        100% {
                            transform: scale(0.7);
                        }
                    }

                    h4 {
                        -webkit-animation-name: slideOutUp;
                        animation-name: slideOutUp;
                        -webkit-animation-duration: 0.4s;
                        animation-duration: 0.4s;
                        -webkit-animation-fill-mode: both;
                        animation-fill-mode: both;
                    }

                    @-webkit-keyframes slideOutUp {
                        0% {
                            -webkit-transform: translateY(0);
                            transform: translateY(0);
                        }

                        100% {
                            -webkit-transform: translateY(-30%);
                            transform: translateY(-30%);
                        }
                    }

                    @keyframes slideOutUp {
                        0% {
                            -webkit-transform: translateY(0);
                            transform: translateY(0);
                        }

                        100% {
                            -webkit-transform: translateY(-30%);
                            transform: translateY(-30%);
                        }
                    }

                    .caption {
                        display: block !important;
                        width: 100%;
                        float: left;
                        text-align: left;
                        -webkit-animation-name: fadeIn;
                        animation-name: fadeIn;
                        -webkit-animation-duration: 0.4s;
                        animation-duration: 0.4s;
                        -webkit-animation-fill-mode: both;
                        animation-fill-mode: both;
                    }

                    @-webkit-keyframes fadeIn {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    @keyframes fadeIn {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }
                }

                @keyframes Wait-And-Start {
                    from {
                        transform: translateY(0);
                    }

                    to {
                        transform: translateY(200%);
                    }
                }
            }

            mat-card-actions {
                padding-top: 0 !important;
                display: flex;
                justify-content: center;
                text-align: center;
                font-weight: bold;
                font-size: small;
            }
        }
    }
}

.justify-self-end {
    justify-self: end;
}

//TODO remove this hotfix with better solution
ceres-value-picker.custom-style-picker {
    input.mat-input-element {
        height: 12px !important;
    }
    button.ceres-button {
        margin-right: -20px;
    }
}

[appearance='table-date-range'] .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    background-color: var(--ceres-white);
    align-items: center;
    justify-content: center;
}

[appearance='table-date-range-footer'] .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    background-color: var(--ceres-shaded-color);
    align-items: center;
    justify-content: center;
}

.custom-input-invalid .mat-mdc-text-field-wrapper {
    border: 1px solid var(--ceres-feedback-red) !important;
}

.custom-static-white {
    color: var(--ceres-static-white) !important;
}

.custom-text-color {
    color: var(--ceres-input) !important;
}

//##########################
//DARK THEME ADJUSTMENTS
//##########################

body[data-theme='dark'] #sie-boilerplate {
    .table-container {
        border: none;

        .mat-mdc-table {
            background: transparent;

            .mat-mdc-cell {
                color: var(--ceres-static-white);
            }

            mat-header-row {
                background: var(--ceres-white);
                border: none;
            }

            mat-row {
                background: var(--ceres-white);
                border-top: 1px solid var(--ceres-primary-color-8);
                border-bottom: none;
            }

            &:not(.not-striped) {
                .mat-mdc-row:nth-child(even) {
                    background-color: var(--ceres-primary-color-8);

                    //.mat-mdc-cell {
                    //  background-color: var(--ceres-primary-color-8);
                    //}
                }

                .mat-mdc-row:nth-child(odd) {
                    background-color: var(--ceres-white);

                    //.mat-mdc-cell {
                    //  background-color: var(--ceres-white);
                    //}
                }
            }
        }
    }

    mat-paginator {
        background: transparent;
        color: var(--ceres-input);

        .mat-mdc-select-min-line,
        .mat-mdc-select-arrow {
            color: var(--ceres-input);
        }
    }

    .menu-wrapper li a:hover,
    .menu-wrapper li.active,
    .menu-wrapper li.open {
        background: transparent;
        color: var(--ceres-primary-color-1);
    }

    .menu-wrapper li a div:hover,
    .menu-wrapper li.active a div {
        background: transparent;
        color: var(--ceres-primary-color-1);
        border-color: var(--ceres-primary-color-1);
    }

    .menu-wrapper li.active a,
    .menu-wrapper li.open > a {
        color: var(--ceres-primary-color-1);
    }

    .tile-container .tile-content {
        background: var(--ceres-sidenav-color);
    }

    .filter-container {
        background: transparent;
    }

    .inline-navbar {
        .navbar-nav-item {
            background: var(--ceres-white);

            &:hover {
                filter: brightness(0.8);
            }
        }

        .navbar-actions {
            background: var(--ceres-white);
            color: var(--ceres-input);

            &:hover {
                filter: brightness(0.8);
            }
        }
    }

    .form-container .options-form {
        background-color: var(--ceres-sidenav-color);
    }

    .mat-custom.mat-mdc-form-field-appearance-fill mat-datepicker-toggle button {
        color: var(--ceres-primary-color-1);
    }

    mat-checkbox {
        .mat-mdc-checkbox-label {
            color: var(--ceres-input);
        }
        .mat-mdc-checkbox-frame {
            border-color: var(--ceres-input);
        }

        &.mat-mdc-checkbox-disabled .mat-mdc-checkbox-label {
            color: var(--ceres-white-opaque);
        }
        &.mat-mdc-checkbox-disabled .mat-mdc-checkbox-frame {
            border-color: var(--ceres-white-opaque);
        }
    }

    mat-chip-grid.mat-mdc-form-field-label {
        color: var(--ceres-input);
    }

    .search-container-standalone mat-icon,
    .search-container-standalone input[type='search'],
    .search-container mat-icon,
    .search-container input[type='search'] {
        color: var(--ceres-input);
    }

    .new-view-container .tab-content-container {
        background-color: var(
            --ceres-sidenav-color
        ); //TODO future transparent - project-new.component.scss line 92 also remove
    }
    .back-button,
    .project-info .back-button {
        //WIP
        background-color: var(--ceres-sidenav-color);
    }

    ceres-layout.service .content--container {
        background-color: var(--ceres-sidenav-color);
    }

    .mod-logo {
        //TODO MOVE INTO MOD_LOG.scss after file restructuring
        filter: brightness(10) !important;
    }

    .mat-radio-label-content,
    .mat-mdc-slide-toggle-content {
        color: var(--ceres-static-white);
    }

    ceres-module .module-container .task-container .percentage,
    ceres-module .module-container .module .part2 {
        background-color: var(--ceres-sidenav-color);
    }
    ceres-module .module-container .task-container .activityTitle {
        color: var(--ceres-static-white);
    }

    ceres-edit-psp-element mat-table .mat-mdc-row {
        &.selected mat-cell {
            backdrop-filter: brightness(1.6);
        }
        &:hover mat-cell {
            backdrop-filter: brightness(1.35);
        }
    }
}

body[data-theme='dark'].projects {
    //TODO remove longterm
    background-color: var(--ceres-sidenav-color) !important;
}

//DARKMODE: OVERLAY CONTAINER COMPONENTS
body[data-theme='dark'] {
    mat-dialog-container .table-container mat-header-row {
        background: var(--ceres-white);
        border: none;
    }

    mat-dialog-container .form-container .options-form {
        background-color: var(--ceres-white);

        &--header {
            background-color: var(--ceres-white);
        }
    }

    .list-filter-row {
        background-color: transparent !important;
    }
}
